import { Colors } from "pancakeswap-uikit/theme"

// PCHAIN currencyId
export const defaultOutputCurrencyId='' // contract to show it selected

//THEME COLORS
export const configBaseColors = {
    failure: "#ED4B9E",
    primary: "#8300E9",
    primaryBright: "#101535",
    primaryDark: "#0098A1",
    secondary: "#7645D9",
    success: "#9A6AFF",
    warning: "#9A6AFF",
  };

  export const configAdditionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D",
  };
  
  export const configLightColors: Colors = {
    ...configBaseColors,
    ...configAdditionalColors,
    secondary: "#9A6AFF",
    background: "#000000",
    backgroundDisabled: "#100032",
    backgroundAlt: "#000000",
    backgroundAlt2: "#FFFFFF",
    cardBorder: "#8300E9",
    contrast: "#FFFFFF",
    dropdown: "#1E1D20",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "#200063",
    inputSecondary: "#8300E9",
    primaryDark: "#0098A1",
    tertiary: "#9A6AFF",
    text: "#FFFFFF",
    textDisabled: "#CCCCCC",
    textSubtle: "#FFFFFF",
    disabled: "#CCCCCC",
    gradients: {
      bubblegum: "#100032",
      inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
      cardHeader: "#100032",
      blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
      violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
      violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
      gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
    },
  };
  
  export const configDarkColors: Colors = {
    ...configBaseColors,
    ...configAdditionalColors,
    secondary: "#9A6AFF",
    background: "#000000",
    backgroundDisabled: "#100032",
    backgroundAlt: "#000000",
    backgroundAlt2: "#FFFFFF",
    cardBorder: "#8300E9",
    contrast: "#FFFFFF",
    dropdown: "#1E1D20",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "#200063",
    inputSecondary: "#8300E9",
    primaryDark: "#0098A1",
    tertiary: "#9A6AFF",
    text: "#FFFFFF",
    textDisabled: "#CCCCCC",
    textSubtle: "#FFFFFF",
    disabled: "#CCCCCC",
    gradients: {
      bubblegum: "#100032",
      inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
      cardHeader: "#100032",
      blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
      violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
      violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
      gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
    },
  };

  export const particlesDarkColors=["#E02B20", "#8300E9"]
  export const particlesLightColors=["E02B20", "#8300E9"]