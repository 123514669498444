import { Colors } from "./types";
import {configBaseColors, configAdditionalColors, configLightColors, configDarkColors} from 'config/pchain/pchain-config'

export const baseColors= configBaseColors
export const additionalColors= configAdditionalColors
export const lightColors= configLightColors
export const darkColors= configDarkColors



