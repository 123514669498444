import React from "react";
import * as IconModule from "pancakeswap-uikit/components/Svg"
//".";
import { SvgProps } from "pancakeswap-uikit/components/Svg/types";
 
const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> };

const IconComponent: React.FC<{ iconName: string } & SvgProps> = ({ iconName, ...props }) => {
  const IconElement = Icons[`${iconName}Icon`];
  return IconElement ? <IconElement {...props} /> : null;
};

export default IconComponent;
