import React from "react";
import {Text} from "@pancakeswap/uikit";
import Dropdown from "pancakeswap-uikit/components/Dropdown/Dropdown";
import {Button} from "@pancakeswap/uikit";
import LanguageIcon from "pancakeswap-uikit/components/Svg/Icons/Language";
import MenuButton from "./MenuButton";
import {Colors} from "pancakeswap-uikit/theme/types";
import { Language } from "./types";
import { Position } from "../Dropdown/types";
import {Scale}  from "pancakeswap-uikit/components/Button/types";

interface Props {
  currentLang: string;
  langs: Language[];
  setLang: (lang: Language) => void;
  color: keyof Colors;
  dropdownPosition?: Position;
  buttonScale?: Scale;
  hideLanguage?: boolean;
}

const LangSelector: React.FC<Props> = ({
  currentLang,
  langs,
  color,
  setLang,
  dropdownPosition = "bottom",
  buttonScale = "md",
  hideLanguage = false,
}) => (
  <Dropdown
    position={dropdownPosition}
    target={
      <Button scale={buttonScale} variant="text" startIcon={<LanguageIcon color={color} width="24px" />}>
        {!hideLanguage && <Text color={color}>{currentLang?.toUpperCase()}</Text>}
      </Button>
    }
  >
    {langs.map((lang) => (
      <MenuButton
        key={lang.locale}
        fullWidth
        onClick={() => setLang(lang)}
        // Safari fix
        style={{ minHeight: "32px", height: "auto" }}
      >
        {lang.language}
      </MenuButton>
    ))}
  </Dropdown>
);

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
