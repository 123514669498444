import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
 
const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 198 199" {...props}>
      <path d="M0 433 l0 -26 25 23 c31 29 31 30 0 30 -20 0 -25 -5 -25 -27z"/>
      <path d="M416 428 c16 -18 30 -35 32 -37 1 -2 2 12 2 32 0 35 -2 37 -32 37 l-32 0 30 -32z"/>
      <path d="M213 423 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
      <path d="M200 383 c0 -9 3 -13 7 -10 3 4 12 2 20 -4 10 -9 13 -7 13 8 0 12 -8 19 -20 20 -12 1 -20 -4 -20 -14z"/>
      <path d="M150 370 c0 -13 5 -18 15 -14 12 5 13 10 4 20 -15 19 -19 18 -19 -6z"/>
      <path d="M298 363 c0 -5 -2 -14 -2 -22 -1 -8 -7 -16 -15 -19 -9 -3 -11 0 -6 9 5 8 -4 3 -19 -11 -20 -18 -25 -20 -20 -7 3 10 1 17 -5 17 -7 0 -14 -6 -17 -14 -3 -8 -12 -17 -20 -20 -11 -4 -12 -2 -4 13 7 14 6 20 -6 25 -8 3 -13 2 -10 -2 8 -13 -24 -54 -37 -49 -7 2 -17 -2 -24 -9 -7 -7 -31 -15 -53 -19 -49 -8 -54 -19 -43 -77 6 -31 14 -44 28 -47 11 -2 35 -16 53 -32 18 -16 36 -29 39 -29 4 0 -8 13 -27 29 -38 31 -38 35 -2 46 22 6 23 5 11 -17 -11 -20 -11 -22 3 -10 13 10 17 10 22 0 3 -7 4 -3 2 10 -4 20 4 27 28 23 4 -1 14 4 22 10 9 8 14 8 14 1 0 -6 -8 -16 -17 -21 -11 -6 -13 -11 -5 -11 7 0 23 11 36 25 25 26 47 35 26 10 -13 -16 -6 -35 13 -34 8 0 8 2 -1 6 -9 3 -3 14 18 35 38 36 53 29 19 -10 l-24 -27 28 24 c15 14 27 30 27 37 0 6 17 15 37 19 56 10 73 22 73 53 0 35 -23 78 -39 74 -7 -1 -20 3 -29 10 -10 9 -13 9 -9 1 8 -13 3 -18 -35 -27 -23 -5 -26 -3 -23 19 2 22 -3 36 -7 18z m79 -65 c-19 -10 -11 -41 9 -34 8 3 14 1 14 -5 0 -16 -29 -22 -45 -9 -8 7 -15 21 -15 31 0 20 29 41 44 32 5 -3 1 -10 -7 -15z m-73 -30 c11 -42 8 -50 -14 -38 -15 8 -20 8 -20 -3 0 -13 -11 -17 -52 -18 -10 0 -18 -4 -18 -8 0 -4 -6 -8 -12 -9 -7 0 -23 -3 -34 -7 -17 -5 -23 -2 -27 17 -9 32 -8 48 2 48 5 0 12 -11 15 -25 9 -36 36 -32 36 4 0 43 19 49 48 15 l24 -29 -6 33 c-6 34 9 43 33 20 10 -11 11 -10 6 5 -4 9 -3 17 3 17 5 0 12 -10 16 -22z m-194 -54 c0 -8 -7 -17 -15 -20 -8 -4 -13 -10 -10 -14 5 -8 -13 -25 -19 -19 -2 2 -7 18 -10 36 -6 25 -4 32 11 35 25 6 43 -2 43 -18z"/>
      <path d="M75 210 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7 -4 -4 -10z"/>
      <path d="M117 332 c12 -11 19 -23 15 -28 -4 -5 1 -2 11 5 9 7 17 16 17 19 0 3 -3 2 -7 -1 -3 -4 -18 0 -32 9 l-26 15 22 -19z"/>
      <path d="M208 343 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
      <path d="M46 305 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2 2 -6 -3 -10 -12z"/>
      <path d="M75 310 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z"/> 
      <path d="M401 194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
      <path d="M343 190 c2 -8 12 -16 23 -17 13 -2 15 -1 4 4 -8 3 -19 11 -23 17 -6 7 -7 6 -4 -4z"/>
      <path d="M354 114 c-17 -18 -28 -34 -24 -34 7 0 43 38 54 58 12 20 3 13 -30 -24z"/>
      <path d="M218 123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
      <path d="M288 122 c-13 -5 -15 -11 -8 -22 6 -9 10 -10 10 -3 0 7 7 10 15 7 11 -5 13 -2 9 10 -3 9 -7 16 -8 15 0 0 -9 -3 -18 -7z"/>
      <path d="M172 100 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
      <path d="M226 94 c-8 -21 -4 -25 14 -14 11 7 12 12 4 20 -8 8 -13 7 -18 -6z"/>
      <path d="M421 58 c-14 -17 -38 -36 -55 -44 -31 -13 -30 -13 27 -14 l57 0 0 45 c0 25 -1 45 -2 44 -2 0 -14 -14 -27 -31z"/>
      <path d="M0 37 l0 -37 53 1 c45 0 49 2 27 10 -14 6 -37 22 -52 36 l-28 27 0 -37z"/>
      <path d="M203 53 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
    </Svg>
  );
};

export default Icon;
