import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
 
const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
        fill="black"
      />
    </Svg>
  );
};

export default Icon;
